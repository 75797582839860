import { Fragment } from "react";
import PropTypes from "prop-types";
import getChunks from "./getChunks";
import parseToRegex from "./parseToRegex";

const Highlight = ({
  children: text, search, HighlightComponent, ...props
}) => {
  let content;
  if (search) {
    const regex = parseToRegex(search);
    const chunks = getChunks(text, regex);
    content = chunks.map((chunk) => {
      const key = `${chunk.start}-${chunk.end}`;
      const chunkText = text.slice(chunk.start, chunk.end);
      if (chunk.highlight) {
        return (
          <HighlightComponent key={key}>
            <span>{chunkText}</span>
          </HighlightComponent>
        );
      }

      return (
        <Fragment key={key}>
          <span>{chunkText}</span>
        </Fragment>
      );
    });
  } else {
    content = text;
  }

  return (
    <span {...props}>
      {content}
    </span>
  );
};

export default Highlight;

Highlight.propTypes = {
  children: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  HighlightComponent: PropTypes.elementType,
};

Highlight.defaultProps = {
  HighlightComponent: "mark",
};
