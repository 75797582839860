import { Box, OutlinedInput } from "@mui/material";
import FormSection from "paymentRequest/components/FormSection";
import FormField from "components/FormField";
import translate from "./translate";
import Row from "./Row";

const CardDetailsForm = ({ formState, ...props }) => (
  <FormSection title={translate("card_form_title")} {...props}>
    <Row>
      <FormField
        error={formState.vgsFieldError("transaction.billing_data.card_name")}
        focused={formState.vgsFieldFocused("transaction.billing_data.card_name")}
        fullWidth
        label={translate("form_card_holder")}
        onClick={formState.focusCardNameField}
        required
      >
        <OutlinedInput
          id="cc-name-container"
          inputComponent="div"
        />
      </FormField>
    </Row>

    <Row>
      <FormField
        error={formState.vgsFieldError("transaction.billing_data.card_number")}
        focused={formState.vgsFieldFocused("transaction.billing_data.card_number")}
        fullWidth
        label={translate("form_card_number")}
        onClick={formState.focusCardNumberField}
        required
      >
        <OutlinedInput
          id="cc-number-container"
          inputComponent="div"
        />
      </FormField>
    </Row>

    <Row display="flex">
      <Box flex="2 1" mr={1}>
        <FormField
          error={formState.vgsFieldError("transaction.billing_data.card_exp")}
          focused={formState.vgsFieldFocused("transaction.billing_data.card_exp")}
          fullWidth
          label={translate("form_card_expiration")}
          onClick={formState.focusCardExpirationField}
          required
        >
          <OutlinedInput
            id="cc-exp-container"
            inputComponent="div"
          />
        </FormField>
      </Box>
      <Box flex="1 1" ml={1}>
        <FormField
          error={formState.vgsFieldError("transaction.billing_data.card_cvv")}
          focused={formState.vgsFieldFocused("transaction.billing_data.card_cvv")}
          fullWidth
          label={translate("form_card_cvv")}
          onClick={formState.focusCardCVVField}
          required
        >
          <OutlinedInput
            id="cc-cvv-container"
            inputComponent="div"
          />
        </FormField>
      </Box>
    </Row>
  </FormSection>
);

export default CardDetailsForm;
