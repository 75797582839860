import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import RadioButtonList from "components/RadioButtonList";
import FormField from "components/FormField";

const RadioButtonGroup = ({
  disabled,
  error,
  inline,
  label,
  name,
  onChange,
  options,
  required,
  value,
}) => {
  const radioButtonListOptions = options.map((option) => ({
    disabled,
    ...option,
  }));

  return (
    <FormField
      disabled={disabled}
      error={error}
      fullWidth
      label={label}
      required={required}
    >
      <Box pt={1}>
        <RadioButtonList
          inline={inline}
          name={name}
          onChoose={onChange}
          options={radioButtonListOptions}
          selectedOption={value}
        />
      </Box>
    </FormField>
  );
};

RadioButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  required: PropTypes.bool,
  value: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
  disabled: undefined,
  error: undefined,
  inline: false,
  label: undefined,
  onChange: () => {},
  options: [],
  required: undefined,
  value: "",
};

export default RadioButtonGroup;
