import formatCurrency from "utils/formatCurrency";
import TextField from "components/TextField";

const CurrencyTextField = ({ value, onChange, ...props }) => {
  const textFieldValue = value === "" ? "" : maskValue(value);

  const handleChange = (e) => {
    const { value: inputValue } = e.target;

    const normalizedValue = unmaskValue(inputValue);

    e.target.value = normalizedValue;

    if (normalizedValue >= 0) onChange(e, normalizedValue);
  };

  return <TextField {...props} onChange={handleChange} value={textFieldValue} />;
};

export default CurrencyTextField;

const maskValue = formatCurrency;

const unmaskValue = (number) => {
  const maximumFractionDigits = 2;
  // divide it by 10 power the maximum fraction digits.
  return clean(number) / (10 ** maximumFractionDigits);
};

// strips everything that is not a number (positive or negative)
const clean = (number) => Number(number.toString().replace(/[^0-9-]/g, ""));
