import TextField from "components/TextField";

const ZipField = ({
 value, onChange, inputProps, ...props
}) => (
  <TextField
    inputProps={{ maxLength: 10, inputMode: "numeric", ...inputProps }}
    onChange={(e) => {
      e.target.value = unmaskValue(e.target.value);
      onChange(e);
    }}
    value={maskValue(value)}
    {...props}
  />
);

const unmaskValue = (value) => value.replace(/-/g, "");

const maskValue = (value) => {
  if (value === null || value === undefined) return "";
  const onlyDigits = value.replace(/\D/, "");
  if (onlyDigits.length <= 5) return onlyDigits;
  return [onlyDigits.slice(0, 5), "-", onlyDigits.slice(5, 9)].join("");
};

export default ZipField;
