import cond from "lodash/cond";
import isArray from "lodash/isArray";
import isFunction from "lodash/isFunction";
import isString from "lodash/isString";
import stubTrue from "lodash/stubTrue";
import requestToServer from "utils/requestToServer";

const normalizeDataSource =
  (dataSource, getLabel) => {
    const getOptions = (value) => new Promise((resolve, reject) => {
      const isStringAction =
          () => fetchOptions(`${dataSource}?query=${encodeURIComponent(value)}`)
            .then((options) => filterOptions(options, getLabel, value))
            .then(resolve);

      const isArrayAction =
          () => resolve(filterOptions(dataSource, getLabel, value));

      const isFunctionAction =
          () => resolve(dataSource(value));

      const isOtherAction =
          () => reject(new Error("Data source should be a string (endpoint), an array or a function"));

      const exec = cond([
        [isString, isStringAction],
        [isArray, isArrayAction],
        [isFunction, isFunctionAction],
        [stubTrue, isOtherAction],
      ]);
      exec(dataSource);
    });

    return getOptions;
  };

export default normalizeDataSource;

export const filterOptions =
  (options, getLabel, value) => options.filter(
    (option) => getLabel(option).match(new RegExp(value, "gi")),
  );

const fetchOptions =
  (path) => requestToServer({
    path,
    method: "GET",
  }).then((res) => res.json())
    .then((data) => data.suggestions);
