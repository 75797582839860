import clsx from "clsx";
import formatCurrency from "utils/formatCurrency";
import CurrencyTextField from "components/CurrencyTextField";
import styles from "./styles.module.scss";
import t from "../translate";

const OtherAmountLabel = ({
 min, value, onChange, showValue, error, inputRef, onBlur, onFocus,
}) => {
  const helperText = min > 0 ? `min. ${formatCurrency(min)}` : undefined;
  const textFieldValue = showValue ? value : "";

  const handleChange = (_e, amount) => {
    onChange(amount);
  };

  return (
    <div className={styles.container}>
      {t("other_amount")}
      :
      <CurrencyTextField
        className={styles.textField}
        classes={{
          input: clsx(styles.input, {
            [styles.inputDisplayValueHidden]: !showValue,
          }),
        }}
        error={error}
        helperText={helperText}
        id="other-amount"
        inputProps={{ ref: inputRef, onBlur, onFocus }}
        onChange={handleChange}
        value={textFieldValue}
      />
    </div>
  );
};

export default OtherAmountLabel;
