const getChunks = (text, regex) => {
  const { length } = text;
  const chunks = [];
  const append = (start, end, highlight) => {
    if (end - start > 0) {
      chunks.push({
        start,
        end,
        highlight,
      });
    }
  };

  let match;
  let lastIndex = 0;
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(text)) !== null) {
    const matchIndexEnd = match.index + match[0].length;
    append(lastIndex, match.index, false);
    append(match.index, matchIndexEnd, true);
    lastIndex = matchIndexEnd;
  }

  append(lastIndex, length, false);

  return chunks;
};

export default getChunks;
