import { useMemo } from "react";
import Box from "@mui/material/Box";
import fieldError from "utils/formikUtils/fieldError";
import TextField from "components/TextField";
import Autocomplete from "components/Autocomplete";
import ZipField from "components/ZipField";
import RadioButtonList from "components/RadioButtonList";
import FormSection from "paymentRequest/components/FormSection";
import translate from "./translate";
import Row from "./Row";
import styles from "./styles.module.scss";

const AddressForm = ({
  formikProps,
  billingAddress,
  billingAddressIncomplete,
  states,
  ...props
}) => {
  const handleFieldChange = (e, field, fieldProps) => {
    fieldProps.setFieldTouched(field, true);
    fieldProps.handleChange(e);
  };

  const handleAutocompleteFieldChange = (option, field, fieldProps) => {
    fieldProps.setFieldTouched(field, true);
    fieldProps.setFieldValue(field, option.value);
  };

  const useFromFileOptions = useMemo(() => [
    {
      label: (
        <div>
          {translate("form_address_use_from_file")}
          <div className={styles.address}>{billingAddress}</div>
        </div>),
      value: "true",
      disabled: billingAddressIncomplete,
    },
    {
      label: translate("form_address_use_different"),
      value: "false",
    },
  ], [billingAddress, billingAddressIncomplete]);

  return (
    <FormSection title={translate("address_form_title")} {...props}>
      <RadioButtonList
        name="useFromFile"
        onChoose={(e) => handleFieldChange(e, "useFromFile", formikProps)}
        options={useFromFileOptions}
        selectedOption={formikProps.values.useFromFile}
      />
      {formikProps.values.useFromFile === "false" && (
        <Box pt={2}>
          <Row>
            <TextField
              error={fieldError(formikProps, "addressLine1")}
              fullWidth
              id="addressLine1"
              inputProps={{ autoComplete: "address-line1" }}
              label={translate("form_address_line_1")}
              onChange={(e) => handleFieldChange(e, "addressLine1", formikProps)}
              required
              value={formikProps.values.addressLine1}
            />
          </Row>

          <Row>
            <TextField
              error={fieldError(formikProps, "addressLine2")}
              fullWidth
              id="addressLine2"
              inputProps={{ autoComplete: "address-line2", required: true }}
              label={translate("form_address_line_2")}
              onChange={(e) => handleFieldChange(e, "addressLine2", formikProps)}
              value={formikProps.values.addressLine2}
            />
          </Row>

          <Row alignItems="flex-start" display="flex">
            <Box flexGrow={1} mr={1}>
              <TextField
                error={fieldError(formikProps, "addressCity")}
                fullWidth
                id="addressCity"
                inputProps={{ autoComplete: "address-level2" }}
                label={translate("form_address_city")}
                onChange={(e) => handleFieldChange(e, "addressCity", formikProps)}
                required
                value={formikProps.values.addressCity}
              />
            </Box>
            <Box display="flex" flexGrow={1} ml={1}>
              <Box flexGrow={1} mr={2}>
                <Autocomplete
                  autofill
                  dataSource={states}
                  error={fieldError(formikProps, "addressState")}
                  label={translate("form_address_state")}
                  minLength={0}
                  onOptionSelected={(option) => handleAutocompleteFieldChange(option, "addressState", formikProps)}
                  required
                  selectedOption={formikProps.values.addressState}
                />
              </Box>
              <Box flexGrow={1}>
                <ZipField
                  error={fieldError(formikProps, "addressZip")}
                  fullWidth
                  id="addressZip"
                  inputProps={{ autoComplete: "postal-code" }}
                  label={translate("form_address_zip")}
                  onChange={(e) => handleFieldChange(e, "addressZip", formikProps)}
                  required
                  value={formikProps.values.addressZip}
                />
              </Box>
            </Box>
          </Row>
        </Box>
      )}
    </FormSection>
  );
};

export default AddressForm;
