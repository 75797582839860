import { Box, Typography } from "@mui/material";
import Bold from "components/Typography/Bold";
import Title from "components/Title";
import Modal from "components/Modal";
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableHeadCell,
  TableCell,
  TableFooter,
} from "components/Table";
import formatCurrency from "utils/formatCurrency";
import toUSDateFormat from "utils/toUSDateFormat";
import translate from "./translate";

const DetailsModal = ({
  charges,
  onCancelButtonClick,
  onDoneButtonClick,
  total,
}) => (
  <Modal
    cancelButtonText="Back"
    disableBackdropClick
    disableGutters
    key="two"
    onCancelButtonClick={onCancelButtonClick}
    onDoneButtonClick={onDoneButtonClick}
    open
    title={translate("claim_details_title")}
    wide
  >
    <Title pb={0}>{translate("included_procedures")}</Title>
    <Box pb={2} px={2} textAlign="left">
      <Typography variant="caption">
        <Bold>
          {charges && translate("procedures_associated", { total: formatCurrency(total) })}
        </Bold>
      </Typography>
    </Box>
    <DetailsTable charges={charges} total={total} />
  </Modal>
);

const DetailsTable = ({ charges, total }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableHeadCell>
          {translate("date_column")}
        </TableHeadCell>
        <TableHeadCell>
          {translate("item_column")}
        </TableHeadCell>
        <TableHeadCell align="right">
          {translate("cost_column")}
        </TableHeadCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {charges.map((charge) => (
        <TableRow key={charge.id}>
          <TableCell>{toUSDateFormat(charge.date)}</TableCell>
          <TableCell>{charge.description}</TableCell>
          <TableCell align="right">{formatCurrency(charge.balance)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
    <TableFooter>
      <TableRow>
        <TableCell>
          <Bold>{translate("total")}</Bold>
        </TableCell>
        <TableCell />
        <TableCell align="right">
          <Bold>
            {formatCurrency(total)}
          </Bold>
        </TableCell>
      </TableRow>
    </TableFooter>
  </Table>
);

export default DetailsModal;
