import { useState, useMemo } from "react";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";
import isString from "lodash/isString";
import debounce from "lodash/debounce";
import styles from "./styles.module.scss";
import TextField from "../TextField";
import Hightlight from "../Highlight";
import normalizeDataSource, { filterOptions } from "./normalizeDataSource";

const Autocomplete = ({
  label,
  error,
  selectedOption,
  getValue,
  getLabel,
  dataSource,
  onOptionSelected,
  minLength,
  required,
  debounceTime,
  autofill,
}) => {
  const [value, setValue] = useState(
    selectedOption ? getValue(selectedOption) : "",
  );
  const [options, setOptions] = useState([]);

  const debouncedGetOptions = useMemo(() => {
    const getOptions = normalizeDataSource(dataSource, getLabel);
    const getAndSetOptions = async (query) => {
      setOptions(await getOptions(query));
    };
    return debounce(getAndSetOptions, debounceTime);
  }, [dataSource, debounceTime, getLabel]);

  const handleSuggestionsFetchRequested =
    ({ value: query }) => debouncedGetOptions(query);

  const renderSuggestion = (suggestion, { query }) => {
    const text = getLabel(suggestion);
    return (
      <Hightlight
        // eslint-disable-next-line react/no-unstable-nested-components
        HighlightComponent={({ children }) => (
          <span className={styles.hightlight}>{children}</span>
        )}
        search={query}
        title={text}
      >
        {text}
      </Hightlight>
    );
  };

  const renderInputComponent = (inputProps) => (
    <TextField
      error={error}
      fullWidth
      inputProps={inputProps}
      label={label}
      required={required}
    />
  );

  const handleSuggestionSelected =
    (_event, { suggestion }) => onOptionSelected(suggestion);

  const shouldRenderSuggestions = (query) => query.length >= minLength;

  const handleSuggestionsClearRequested = () => setOptions([]);

  const onInputChange = async (inputValue, input) => {
    const inputIsFocused = document.activeElement.id === input.id;

    if (autofill && !inputIsFocused) {
      const autofilledOption = await findMatchedOption(inputValue);

      if (autofilledOption) {
        setValue(getValue(autofilledOption));
        onOptionSelected(autofilledOption);
        return;
      }
    }

    setValue(inputValue);
    onOptionSelected("");
  };

  const inputProps = {
    value,
    onChange: (event, { newValue }) => onInputChange(newValue, event.target),
    onBlur: (event) => {
      if (isValueMatchedToOption()) return;

      onInputChange(value, event.target);
    },
  };

  const isValueMatchedToOption = () => (value === getValue(selectedOption || ""));

  const findMatchedOption = async (inputValue) => {
    const getOptions = normalizeDataSource(dataSource, getLabel);
    const matchedOptions = await getOptions(inputValue);

    const filteredOptionsByValue = filterOptions(matchedOptions, getValue, inputValue);

    if (filteredOptionsByValue.length === 1) {
      return filteredOptionsByValue[0];
    }

    const filteredOptionsByLabel = filterOptions(matchedOptions, getLabel, inputValue);

    if (filteredOptionsByLabel.length === 1) {
      return filteredOptionsByLabel[0];
    }

    return null;
  };

  return (
    <Autosuggest
      getSuggestionValue={getValue}
      inputProps={inputProps}
      onSuggestionSelected={handleSuggestionSelected}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      renderInputComponent={renderInputComponent}
      renderSuggestion={renderSuggestion}
      shouldRenderSuggestions={shouldRenderSuggestions}
      suggestions={options}
      theme={{
        container: styles.container,
        suggestionsContainer: styles.suggestionContainer,
        suggestionsList: styles.suggestionList,
        suggestion: styles.suggestion,
      }}
    />
  );
};

export default Autocomplete;

Autocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  minLength: PropTypes.number,
  dataSource: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.func,
  ]).isRequired,
  getValue: PropTypes.func,
  getLabel: PropTypes.func,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onOptionSelected: PropTypes.func.isRequired,
  required: PropTypes.bool,
  debounceTime: PropTypes.number,
  autofill: PropTypes.bool,
};

Autocomplete.defaultProps = {
  selectedOption: null,
  error: undefined,
  minLength: 2,
  getValue: (option) => {
    if (isString(option)) {
      return option;
    }
    return option.value;
  },
  getLabel: (option) => {
    if (isString(option)) {
      return option;
    }
    return option.label;
  },
  required: false,
  debounceTime: 200,
  autofill: false,
};
