import { Formik } from "formik";
import formatCurrency from "utils/formatCurrency";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Page from "components/Page";
import translate from "./translate";
import usePaymentFormState from "./usePaymentFormState";
import ProcessingModal from "../ProcessingModal";
import getPaymentAddressFormValidationSchema from "./getPaymentAddressFormValidationSchema";
import CardDetailsForm from "./CardDetailsForm";
import AddressForm from "./AddressForm";

const PaymentForm = ({
  amountToPay,
  billingAddress,
  billingAddressIncomplete,
  billingZip,
  fullName,
  additionalData,
  submitFormPath,
  states,
  onSuccess,
  onRecoverableError,
  onUnrecoverableError,
  processingMessage,
  takingTooLongMessage,
}) => {
  const { paymentFormColumnConstraints } = useTheme();
  const formattedAmount = formatCurrency(amountToPay);
  const formState = usePaymentFormState({
    cardHolderName: fullName,
    submitFormPath,
  });
  const { submitDisabled, onSubmit, error } = formState;

  const billingData = (formValues) => {
    if (formValues.useFromFile === "true") {
      return { address: billingAddress, zip: billingZip };
    }

    const {
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressZip,
    } = formValues;
    const address = formValues.addressLine2
      ? `${addressLine1}, ${addressLine2}`
      : addressLine1;

    return {
      address: `${address}, ${addressCity}, ${addressState} ${addressZip}`,
      zip: addressZip,
    };
  };

  return (
    <Formik
      initialValues={{
        useFromFile: (!billingAddressIncomplete).toString(),
        addressLine1: "",
        addressLine2: "",
        addressCity: "",
      }}
      onSubmit={(values, actions) => {
        const { address, zip } = billingData(values);
        onSubmit(
          amountToPay,
          address,
          zip,
          additionalData,
          onSuccess,
          onRecoverableError,
          onUnrecoverableError,
          () => {
            actions.setSubmitting(false);
          },
        );
      }}
      validationSchema={getPaymentAddressFormValidationSchema()}
    >
      {(props) => (
        <>
          <Page>
            {!!error && <Page.Alert severity="error" variant="standard">{error}</Page.Alert>}
            <Page.Title>{translate("form_title")}</Page.Title>
            <Page.Subtitle>{translate("form_description")}</Page.Subtitle>
            <Page.Content>
              <Grid container justifyContent="center">
                <Grid container item justifyContent="space-around" md={paymentFormColumnConstraints.md * 2}>
                  <Grid item {...paymentFormColumnConstraints}>
                    <CardDetailsForm formState={formState} pb={2} />
                  </Grid>
                  <Grid item {...paymentFormColumnConstraints}>
                    <AddressForm
                      billingAddress={billingAddress}
                      billingAddressIncomplete={billingAddressIncomplete}
                      formikProps={props}
                      pb={2}
                      states={states}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Page.Content>
            <Page.ButtonPrimary disabled={submitDisabled(props)} onClick={props.handleSubmit}>
              {translate("form_button", { amount: formattedAmount })}
            </Page.ButtonPrimary>
          </Page>

          <ProcessingModal
            open={props.isSubmitting}
            processingMessage={processingMessage || translate("processing_description")}
            takingTooLongMessage={takingTooLongMessage || translate("taking_too_long_description")}
          />
        </>
      )}
    </Formik>
  );
};

export default PaymentForm;
