import { useEffect, useState } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { trackEvent, eventNames } from "utils/EventsTracking";
import Modal from "components/Modal";
import ModalCarousel from "components/Modal/ModalCarousel";
import NoticeOfPrivacyPractices from "previsit/TermsOfUse/Agreements/NoticeOfPrivacyPractices";
import TermsOfUsePractice from "previsit/TermsOfUse/Agreements/TermsOfUsePractice";
import TermsOfUsePracticeEs from "previsit/TermsOfUse/Agreements/TermsOfUsePracticeEs";
import useTranslation, { useI18nContext } from "hooks/useTranslation";
import styles from "./styles.module.scss";

const TermsOfUsePracticeSection = ({
  byTappingLabel,
  forCheckIn,
  practicePrivacyNoticeHTML,
  practiceTermsStandaloneUrl,
  termsOfUseVersion,
}) => {
  const [currentItem, setCurrentItem] = useState(0);
  const [open, setOpen] = useState(false);
  const translate = useTranslation("universal.terms_of_use_practice_section");

  useEffect(() => {
    if (open && currentItem === null) {
      setCurrentItem(0);
    } else if (!open && currentItem !== null) {
      setCurrentItem(null);
    }
  }, [currentItem, open]);

  const byTappingLabelText = byTappingLabel || translate(
    forCheckIn ? "by_tapping_get_started_i_agree_to_the" : "by_tapping_continue_i_agree_to_the",
  );

  return (
    <Typography align={forCheckIn ? "left" : "center"} className={styles.termsOfUse} component="div" variant="body2">
      {byTappingLabelText}
      {" "}
      <Link
        className="agreementButton"
        component="button"
        onClick={() => {
          setOpen(true);
          trackEvent(eventNames.AGREEMENT_PRACTICE_TERMS_OF_USE_REVIEWED);
        }}
        style={forCheckIn ? { color: "var(--primary-color)" } : null}
        type="button"
        variant="body2"
      >
        {translate("terms_of_use")}
      </Link>
      {" "}
      {translate("of_this_healthcare_provider")}
      <ModalCarousel
        currentItem={currentItem}
        modals={[
          <TermsOfUsePracticeModal
            forCheckIn={forCheckIn}
            goToPageTwo={() => setCurrentItem(1)}
            key="one"
            onDoneButtonClick={() => setOpen(false)}
            open={open}
            practiceTermsStandaloneUrl={practiceTermsStandaloneUrl}
            termsOfUseVersion={termsOfUseVersion}
          />,
          <NoticeOfPrivacyPracticesModal
            key="two"
            onCancelButtonClick={() => setCurrentItem(0)}
            onDoneButtonClick={() => setOpen(false)}
            open={open}
            practicePrivacyNoticeHTML={practicePrivacyNoticeHTML}
          />,
        ]}
      />
    </Typography>
  );
};

const TermsOfUsePracticeModal = ({
  forCheckIn,
  goToPageTwo,
  onDoneButtonClick,
  open,
  practiceTermsStandaloneUrl,
  termsOfUseVersion,
}) => {
  const translate = useTranslation("universal.terms_of_use_practice_section");

  return (
    <Modal
      disableBackdropClick
      doneButtonText={translate("done")}
      onDoneButtonClick={onDoneButtonClick}
      open={open}
    >
      <Typography
        align="left"
        className={styles.agreementText}
        color="textSecondary"
        component="div"
        variant="body2"
      >
        <LocaleSpecificTermsOfUsePractice
          forCheckIn={forCheckIn}
          goToPageTwo={goToPageTwo}
          practiceTermsStandaloneUrl={practiceTermsStandaloneUrl}
          termsOfUseVersion={termsOfUseVersion}
        />
      </Typography>
    </Modal>
  );
};

const LocaleSpecificTermsOfUsePractice = ({ ...props }) => {
  const { locale } = useI18nContext();

  return locale === "es" ? <TermsOfUsePracticeEs {...props} /> : <TermsOfUsePractice {...props} />;
};

const NoticeOfPrivacyPracticesModal = ({
  onCancelButtonClick,
  onDoneButtonClick,
  open,
  practicePrivacyNoticeHTML,
}) => {
  const translate = useTranslation("universal.terms_of_use_practice_section");

  return (
    <Modal
      cancelButtonText={translate("back")}
      disableBackdropClick
      doneButtonText={translate("done")}
      onCancelButtonClick={onCancelButtonClick}
      onDoneButtonClick={onDoneButtonClick}
      open={open}
    >
      <Typography
        align="left"
        className={styles.agreementText}
        color="textSecondary"
        component="div"
        variant="body2"
      >
        <NoticeOfPrivacyPractices practicePrivacyNoticeHTML={practicePrivacyNoticeHTML} />
      </Typography>
    </Modal>
  );
};

export default TermsOfUsePracticeSection;
